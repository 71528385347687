body {
  font-family: $titillium;
  font-size: rem(18);
  color: $blueDarker;
  background: $blueInfos;
  background: linear-gradient(left, $blueInfos 0%, $blue 50%, $blueInfos 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: all 0.4s $bezier;
}

.row {
    max-width: rem(1316);

    [class*='column'] {
      padding-left: $gutter;
      padding-right: $gutter;
    }
}

[class*='o-wrapper'] {  
  padding-top: 65px;
  padding-bottom: 80px;

  @include bp-lt($small) {
    padding-top: 35px;
    padding-bottom: 50px;
  }

  &[class*='gray'] {
    background: $gray;
    background: linear-gradient(left, $gray 0%, $grayLighter 50%, $gray 100%);
  }
}

.o-table {  
  display: table;
  width: 100%;
  height: 100%;  
}

[class*='o-table__cell'] {
  display: table-cell;

  &[class*='--valignMiddle'] {
    vertical-align: middle;
  }
}

.fullWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: none;
}