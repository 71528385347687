// ========================================= //
// tools.breakpoints
// ========================================= //


// Breakpoint greater than
@mixin bp-gt($device-width) {
  @media screen and (min-width: $device-width +1) {
    @content
  }
}

// Breakpoint lower than
@mixin bp-lt($device-width) {
  @media screen and (max-width: $device-width) {
    @content
  }
}

// Breakpoint between
@mixin bp-bt($min-device-width, $max-device-width) {
  @media screen and (min-width: $min-device-width +1) and (max-width: $max-device-width) {
    @content
  }
}
