.c-products__container {
  background: $white;
  padding-bottom: 0;

  .c-title {
    margin-bottom: 60px;
  }

  .fullWidth {
    .column {
      &:nth-child(2n) {
        [class*='c-product'] {  
          &[class*='item'] {
            @include bp-lt ($medium) {
              border-right: none;
            }
          }
        }
      }

      &:last-child {
        float: left;
      }
    }
  }
}

[class*='c-product'] {  

  &[class*='item'] {
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    position: relative;
      
    @include bp-lt ($small) {
      border-right: none;
    }
  }

  figure {
    margin: 0;
  }

  img {
    display: block;
  }

  &[class*='caption'] {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($red, .9);
    color: $white;
    text-align: center;
    font-size: rem(18);
    font-family: $robotoSlab;
    font-weight: 700;
    height: 80px;
    text-shadow: 1px 1px $redDark;
    padding: 0 $gutter;
  }
}