.c-footer {
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center;
    color: $white;

    .title {
        font-size: rem(19);
        font-weight: 600;
        text-transform: uppercase;
    }

    a {
        color: $red;

        &:hover,
        &:focus {
            color: $redDark;
        }
    }

    span {
        display: block;

        &.phone {
            margin-top: 5px;
        }

        strong {
            color: $red;
            font-weight: normal;
            width: 30px;
            display: inline-block;
        }
    }

    a.phone {
        color: $white;

        strong {
            text-align: end;
            margin-right: 0.5rem;
        }
    }
}