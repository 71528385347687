.c-keyFigures__container {
  background: $white;

  .o-equalizer__item {
    @include bp-lt($small) {
      height: auto !important;
    }
  }

}

.c-keyFigures {
  position: relative;
  padding: 45px;  
  height: 100%; 
  text-align: center;

  @include bp-lt ($medium) {
    padding: 15px;
  }

  @include bp-lt ($small) {
    border-bottom: 1px solid $grayLight;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -($gutter);
    width: 1px;
    height: 100%;
    background: $grayLight;

    @include bp-lt ($small) {
      display: none;
    }
  }

  .number,
  .baseline {
    display: block;
    color: $blue;
  }

  .number {
    font-family: $robotoSlab;
    font-size: rem(72);
    font-weight: 700;
    line-height: rem(90);

    @include bp-lt ($medium) {
      font-size: rem(40);
    }

    @include bp-lt ($small) {
      font-size: rem(30);
    }
  }

  .baseline {
    font-size: rem(20);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 45px ;
  }

  &.first {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -($gutter);
      width: 1px;
      height: 100%;
      background: $grayLight;
    }

    .number,
    .baseline {
      color: $red;      
    }
  }
}