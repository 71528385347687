.c-solutions {
  text-align: center;

  .c-title {
    margin-bottom: 65px;

    @include bp-lt($small) {
      margin-bottom: 35px;
    }
  }

  .o-equalizer {
    .o-equalizer__item {
      padding-bottom: 5px;
      
      &:nth-child(2),
      &:nth-child(6) {
        padding-right: 5px;

        @include bp-lt ($small) {
          padding-right: 0;
        }
      }

    }
  }
  
  [class*='c-block'] {
    background: $white;
    padding: 20px;
    //border-bottom: 5px solid $gray;

    &[class*='svg']  {
      background: $red;
      color: $white;

      svg {
        max-width: 90px;
        max-height: 90px;
      }

      span {
        display: block;
        margin-top: 40px;
        text-transform: uppercase;
        text-shadow: 1px 1px darken($redDark, 20%);
        font-weight: 600;
      }
    }
  }  
}