.c-job {
  text-align: center;

  .o-equalizer__item {
    @include bp-lt($small) {
      height: auto !important;
    }
  }

  .c-title__baseline {
    margin-bottom: 65px;

    @include bp-lt($small) {
      margin-bottom: 35px;
    }
  }
  
  .c-table__container {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;      
      border-radius: 50% / 20px;
      box-shadow: rgba(0,0,0,0.2) 0 5px 10px;
      clip: rect(20px, auto, 50px, 0);
      display: block;
      margin: 0 auto;
      width: 90%;
      height: 20px;
    }
  }

  .c-table {
    overflow: hidden;

    .c-list {
      @include bp-lt($small) {
        border-bottom: 1px solid $gray;
      }
    }
  }

  [class*='c-table'] {
    background: $white;
    height: 100%;        

    .row {
      margin: 0;
      height: 100%;
    }
    
    .column {
      position: relative;
      height: 100%; 

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: $grayLight;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    .c-list {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    [class*='heading'] {
      border-bottom: 1px solid $gray;
      text-transform: uppercase;
      font-weight: 600;
      font-size: rem(16);
      padding: 24px 15px;
      height: auto;
    }
    
    &[class*='blue'] {
      background: $blueInfos;
      background: linear-gradient(top, $blueInfos 0%, $blue 100%);

      &,
      [class*='heading'] {
        color: $white;        
        border-color: $blue;
      }

      [class*='heading'] {
        background: none;
      }

      .c-list {
        li {
          &:after {
            background-image: url('#{$img}svg/c-separator--blue.svg');
          }
        }
      }
    }
  }
}