.c-button {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-family: $robotoSlab;
  font-size: rem(16);
  color: $white;
  padding: 18px 15px;
  width: 100%;
  max-width: 280px;
  background: $red;
  box-shadow: 0 3px 0 $blueDark;

  &:hover,
  &:focus {
    color: $white;
    background: $redDark;
    box-shadow: none;
  }
}