// ========================================= //
// Settings
// ========================================= //

// *Import Font
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,600|Roboto+Slab:400,700);

// *Define Font
$titillium              : 'Titillium Web', sans-serif;
$robotoSlab             : 'Roboto Slab', serif;

// *Define Var
$gutter                 : 0.625rem !default;  //10px
$bezier                 : cubic-bezier(.7, 0, .175, 1);
$img                    : '../../media/images/' !default;

// *Define Color
$white                  : #ffffff !default;
$error                  : #d66666 !default;
$blue                   : #2b3d6a!default;
$blueDark               : #101e33 !default;
$blueDarker             : #141926!default;
$blueInfos              : #152846!default;
$red                    : #bc6a50!default;
$redDark                : darken($red, 5%)!default;
$grayLighter            : #e9eaed!default;
$grayLight              : #d4dcdf!default;
$gray                   : #c2c5ce!default;
$salmon                 : #bc6a50!default;

// *Define Breakpoints
$xsmall                 : 26.25em !default;  //420px
$small                  : 40em !default;     //640px
$xmedium                : 52.5em !default;   //840px
$medium                 : 64em !default;     //1024px
$large                  : 90em !default;     //1440px
