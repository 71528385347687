[class*='c-title'] {  
  display: block;
  color: $blue;
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: rem(30);
  text-align: center;
  text-shadow: 1px 1px $white;
  padding: 0 $gutter;

  @include bp-lt($small) {
    font-size: rem(25);
  }

  &[class*='baseline'] {
    font-size: rem(26);

    @include bp-lt($small) {
      font-size: rem(20);
    }
  }
}

.c-list {  
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    &:after {
      content: '';
      display: block;    
      width: 7px;
      height: 7px;
      margin: 10px auto;
      background: url('#{$img}svg/c-separator.svg') no-repeat;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .c-line {
      display: block;
      margin: 10px 0;
    }
  }
}