.c-logo {
  padding: 20px 0;
  background: $white;

  a {
    margin: 0 auto;
    display: block;
    width: 378px;
    height: 150px;
    background: url('#{$img}c-logo.jpg') no-repeat;
    background-size: contain;

    @include bp-lt($small) {
      width: 150px;
      height: 60px;
    }
  }
}

.c-insert {
  width: 100%;
  height: 666px;
  background: url('#{$img}c-header.jpg') bottom center no-repeat;
}

.c-insert__content {
  padding: 35px 30px;
  color: $white;
  text-align: center;
  background: rgba($blue, .9);
  background: linear-gradient(left, rgba($blue, .9) 0%, rgba($blueInfos, .9) 100%);

  .title,
  .subTitle,
  .message {
    display: block;
  }

  .title,
  .subTitle {
    font-family: $robotoSlab;
    font-weight: 700;
    text-shadow: 1px 1px $blueDark;
  }

  .title {
    margin-top: 15px;
    font-size: rem(24);
  }

  .subTitle {
    font-size: rem(20);
  }

  .message {
    margin-top: 55px;
  }
}

.c-groupButton {
  margin-top: 35px;

  .c-button {
    max-width: 190px;

    &:nth-child(2) {
      margin-left: rem(4);

      @include bp-lt(rem(1332)) {
        margin-left: 0;
      }
    }

    @include bp-lt(rem(1332)) {
      float: none;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}
