[class*='c-infos'] {
  width: 100%;
  height: 680px;
  background: $blueInfos url('#{$img}c-infos.jpg') top left no-repeat;
  position: relative;

  @include bp-lt($medium) {
    height: auto;
  }

  [class*='c-infos'] {
    position: absolute;
    width: 50%;
    top: 0;

    @include bp-lt($medium) {
      padding: 30px 15px;
      position: static;
      width: 100%;
    }

    .article {
      background: $white;
      font-family: $robotoSlab;
      font-weight: 700;
      text-align: center;
    }

    .title {
      font-family: $robotoSlab;
      font-weight: 700;

      @include bp-lt($small) {
        padding: 0 $gutter;
        text-align: center;
      }
    }
  }
  
  [class*='__midest'] {    
    left: 0;    

    .column {
      margin-left: 29%;
    }

    .c-content {
      @include bp-lt($small) {
        overflow: hidden;
      }
    }

    .title {
      color: $white;
      font-size: rem(30);
      margin-bottom: 75px;

      @include bp-lt($small) {
        font-size: rem(25);
        margin-bottom: 35px;
      }
    }
    
    .article {      
      padding-top: 60px;      
      font-size: rem(22);      

      img {
        margin: 0 auto 35px auto;
        display: block;
      }
      
      .content {
        background: $grayLighter;
        position: relative;
        padding: 65px 0;

        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 415px 0px 0;
          border-color: transparent $grayLighter transparent transparent;
          border-style: inset;
          transform: rotate(360deg);
          position: absolute;
          top: -10px;
          left: 0;
        }
      }

      span {
        font-size: rem(30);
        text-transform: uppercase;
        display: block;
        color: $salmon;
      }

    }
  }

  [class*='__news'] {
    right: 0;    
    background: url('#{$img}c-infos__news.jpg') top right no-repeat;

    @include bp-lt($small) {
      background: none;
      padding-left: 0;
      padding-right: 0;
    }

    > img {
      @include bp-lt($small) {
        margin-bottom: 15px;
      }
    }
    
    .c-content {
      @include bp-gt ($medium) {
        max-width: 300px;
      }
    }

    .column {
      margin-right: 29%;
    }

    [class*='cell'] {
      @include bp-gt ($medium) {
        position: absolute;
        top: 355px;
      }
    }
    
    .article {
      text-transform: uppercase;
      color: $white;
      background: $blueInfos;
    }

    .title {
      background: $red;
      font-size: rem(24);
      text-shadow: 1px 1px $redDark;
      padding: 30px 0;

      @include bp-lt($small) {
        font-size: rem(20);
        padding: 25px 0;
      }
    }

    .content {
      font-family: $titillium;
      padding: 30px;
      font-size: rem(20);
      font-weight: 600;

      @include bp-lt($small) {
        padding: 25px;
        font-size: rem(18);
      }
    }
  }

  .c-content {
    margin: 0 auto;
    max-width: 415px;    
  }
}